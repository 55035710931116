// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-project-details-tsx": () => import("C:\\Users\\cantr\\Documents\\Me\\dev\\personal-site\\src\\templates\\projectDetails.tsx" /* webpackChunkName: "component---src-templates-project-details-tsx" */),
  "component---src-pages-404-tsx": () => import("C:\\Users\\cantr\\Documents\\Me\\dev\\personal-site\\src\\pages\\404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("C:\\Users\\cantr\\Documents\\Me\\dev\\personal-site\\src\\pages\\about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("C:\\Users\\cantr\\Documents\\Me\\dev\\personal-site\\src\\pages\\index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-tsx": () => import("C:\\Users\\cantr\\Documents\\Me\\dev\\personal-site\\src\\pages\\projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */)
}

